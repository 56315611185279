<template>
    <div class="flex">
        <div class="sub-menu shadow-lg rounded-lg pb-5 bg-white relative">
            <router-link v-if="checkPermission('submit_application')" class="menu-item rounded-lg active" :to="{ name: 'submit_application' }">
                立即提領 提出申請
            </router-link>
            <router-link v-if="checkPermission('immediately_withdraw')" class="menu-item rounded-lg active" :to="{ name: 'immediately_withdraw' }">
                立即提領 撥款處理
            </router-link>
            <router-link v-if="checkPermission('salary_withdraw')" class="menu-item rounded-lg active" :to="{ name: 'salary_withdraw' }">
                月結款項 撥款處理
            </router-link>
            <router-link v-if="checkPermission('error_withdraw')" class="menu-item rounded-lg active" :to="{ name: 'error_withdraw' }">
                異常提領
            </router-link>
        </div>
        <div class="pb-5">
            <keep-alive>
                <router-view />
            </keep-alive>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "WithdrawIssuance",
    computed: {
        ...mapState("permissionStore", ["isAdmin"]),
        routers() {
            return this.$route.meta.params.sideItems ? this.$route.meta.params.sideItems : [];
        },
        menu() {
            return [
                {
                    txt: "立即提領 提出申請",
                    val: "submit_application",
                    show: this.checkPermission("submit_application"),
                },
                {
                    txt: "立即提領 撥款處理",
                    val: "immediately_withdraw",
                    show: this.checkPermission("immediately_withdraw"),
                },
                {
                    txt: "月結款項 撥款處理",
                    val: "salary_withdraw",
                    show: this.checkPermission("salary_withdraw"),
                },
                {
                    txt: "異常提領",
                    val: "error_withdraw",
                    show: this.checkPermission("error_withdraw"),
                },
            ];
        },
    },
    methods: {
        checkPermission(key) {
            if (this.isAdmin) {
                return true;
            }
            if (this.routers.includes(key)) {
                return true;
            }
            return false;
        },
        goFirstTab() {
            this.$nextTick(() => {
                const idx = this.menu.findIndex((i) => i.show === true);
                if (idx !== -1 && this.$route.name === "salary_withdraw_issuance") {
                    this.$router.push({ name: this.menu[idx].val });
                }
            });
        },
    },
    mounted() {
        // 導致有權限的第一頁
        this.goFirstTab();
    },
    activated() {
        // 導致有權限的第一頁
        this.goFirstTab();
    },
};
</script>

<style lang="scss" scoped>
.sub-menu {
    min-width: 248px;
    background: white;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 10px 15px;
    .menu-item {
        width: 100%;
        height: 40px;
        text-align: left;
        font-size: 14px;
        padding: 20px 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 10px 0;
        &.router-link-active {
            background: #ff583314;
        }
    }
}
</style>
